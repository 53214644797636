@mixin ImageAppear {
  transition: transform 0.2s ease-out 0.15s, opacity 0.2s ease-out 0.15s;
  transform: scale(0);
  opacity: 0;

  animation: appearUp 0.75s ease-in;
  animation-fill-mode: both;
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
}

.game-container {
  $spacing: 25px;
  @include fillHeight;
  @include centerChilds;
  padding-top: calc($spacing * 2);
  flex-direction: column;
  gap: $spacing;
  max-width: 1000px;
  margin-inline: auto;
  color: $white-color;
  font-weight: bold;
  .question-container {
    margin: $spacing;
    position: relative;
    max-width: 500px;
    overflow: hidden;
    @include ImageAppear;
    p {
      @include centerChilds;
      position: absolute;
      inset: 0;
      --size: 1.8rem;
      padding: var(--size);
      padding-inline-start: calc(var(--size) + 10px);
      font-size: var(--size);
      @include sm {
        --size: 1rem;
      }
    }
    img {
      width: 100%;
    }
  }
  .answers-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    gap: $spacing;
    padding: $spacing;
    .answer-button {
      @include ImageAppear;
      width: clamp(100px, 100%, 200px);
      margin-inline: auto;
      text-align: center;
      padding: 15px 30px;
      border-radius: 30px;
      position: relative;
      cursor: pointer;
      border: 3px solid $white-color;
      font-size: 1rem;
      span {
        @include centerChilds;
        position: absolute;
        aspect-ratio: 1 /1;
        left: 0;
        top: 0;
        bottom: 0;
        padding: 10px;
        background: $white-color;
        border-radius: 50%;
        color: $primary-color;
      }

      background: linear-gradient(
        to left,
        $primary-color,
        $primary-light-color
      );

      // &:nth-child(even) {
      //   background: linear-gradient(
      //     to right,
      //     $primary-color,
      //     $primary-light-color
      //   );
      // }

      &.true {
        border-color: $success-color;
        span {
          background: $success-color;
          color: $white-color;
        }
      }

      &.wrong {
        border-color: $danger-color;
        span {
          background: $danger-color;
          color: $white-color;
        }
      }
    }
  }
}

@keyframes appearUp {
  from {
    opacity: 0;
    transform: translateY(120%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearLeft {
  from {
    opacity: 0;
    transform: translateX(120%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
