* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@mixin reset {
  padding: 0;
  margin: 0;
}

// Colors
$primary-color: #fd3300;
$primary-light-color: #fd7b00;
$light-bg: white;
$black-color: #000;
$input-border-color: #00000088;
$white-color: #fff;
$success-color: #8cffc6;
$danger-color: #dc3545;
$header-height: 180px;

body {
  position: relative;
  @include reset;
  background: url("../../public/assets/bg.png") no-repeat center center / cover;
  font-family: Arial, Helvetica, sans-serif;
  overflow-x: hidden;
}

// Media
// Small phones
$screen-tn-max: 450px;
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 596px;
// Small tablets (portrait view)
$screen-md-max: 768px;
// Tablets and small desktops
$screen-lg-max: 992px;

// Phone devices
@mixin tn {
  @media (max-width: #{$screen-tn-max}) {
    @content;
  }
}

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

@mixin centerChilds {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin fillHeight {
  min-height: calc(100dvh - $header-height);
}

// Scroll bar
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  transition: 0.5s;
  background: $primary-light-color;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

:root {
  font-size: 18px;
  scroll-behavior: smooth;

  @include lg {
    font-size: 18px;
  }
  @include md {
    font-size: 16px;
  }
  @include sm {
    font-size: 14px;
  }
  // @include tn{
  //     font-size: 10px;
  // }
}

#root {
  min-height: 100dvh;
}

.engaged-count {
  color: $white-color;
  @include fillHeight;
  @include centerChilds;
  flex-direction: column;
  gap: 10px;
  font-size: 4rem;
  font-weight: bold;
}
